
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import { Vue, Component } from 'vue-property-decorator';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import NotAllowedComponent from '@/components/views/settings/NotAllowedComponent.vue';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
  name: 'SchedueleResources',
  components: {
    SettingsTopBar,
    NotAllowedComponent,
  },
})
export default class SchedueleResources extends mixins<UserRightsMixin>(
  UserRightsMixin,
) {
  public comp: any = null;
  public name: string = '';
  private userHasRights: boolean = false;

  private updateComponent(param: any) {
    if (this.canSeeCMSItem) {
      this.userHasRights = true;
    }
    this.name =
      param === 'CalendarSchedules' || param === 'CalendarScheduleEdit'
        ? this.$t('Schedules')
        : this.$t('Resources');
    try {
      import(`@/components/views/Calendar/${param}.vue`).then((module) => {
        this.comp = module.default;
      });
    } catch (e) {
      this.$notification.error({
        message: this.$t('Dogodila se greška') as string,
        description: this.$t('Ovaj proizvod ne postoji') as string,
      });
      this.$router.push({ name: RouteNames.home });
    }
  }

  private beforeRouteEnter(to: any, from: any, next: any) {
    // When first entering the route
    next((vm: any) => vm.updateComponent(to.params.dynamic));
  }

  private beforeRouteUpdate(to: any, from: any, next: any) {
    // When changing from one dynamic route to another
    this.updateComponent(to.params.dynamic);

    next();
  }
}
